import React from "react"
import Layout from "./src/components/layout"

import "bootstrap/js/dist/button"
import "bootstrap/js/dist/collapse"
//import "bootstrap/js/dist/alert"
//import "bootstrap/js/dist/base-component"
//import "bootstrap/js/dist/dropdown"
//import "bootstrap/js/dist/offcanvas"
//import "bootstrap/js/dist/popover"
//import "bootstrap/js/dist/scrollspy"
//import "bootstrap/js/dist/tab"
//import "bootstrap/js/dist/toast"
//import "bootstrap/js/dist/tooltip"

import "./src/components/main.scss"

//import Amplify from "@aws-amplify/core"
//import awsconfig from "./src/aws-exports"

//Amplify.configure(awsconfig)

export const onRouteUpdate = ({ location }, pluginOptions) => {
  const body = document.querySelector(`body`)
  switch (location.pathname) {
    case "/":
      //case "/miplanta":
      body.setAttribute(`class`, `body-overflow`)
      break

    default:
      body.removeAttribute(`class`)
      break
  }
  if (process.env.NODE_ENV === `production`) {
    // wrap inside a timeout to ensure the title has properly been changed
    setTimeout(() => {
      window.dataLayer.push({ event: "page-view" })
    }, 50)
  }
}

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}
