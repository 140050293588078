exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-agricultura-js": () => import("./../../../src/pages/agricultura.js" /* webpackChunkName: "component---src-pages-agricultura-js" */),
  "component---src-pages-asesorias-js": () => import("./../../../src/pages/asesorias.js" /* webpackChunkName: "component---src-pages-asesorias-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-creditoverde-js": () => import("./../../../src/pages/creditoverde.js" /* webpackChunkName: "component---src-pages-creditoverde-js" */),
  "component---src-pages-empresas-js": () => import("./../../../src/pages/empresas.js" /* webpackChunkName: "component---src-pages-empresas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-miplanta-js": () => import("./../../../src/pages/miplanta.js" /* webpackChunkName: "component---src-pages-miplanta-js" */),
  "component---src-pages-residencial-js": () => import("./../../../src/pages/residencial.js" /* webpackChunkName: "component---src-pages-residencial-js" */)
}

